import AlphaFilters from '@nematis/wp-alpha/assets/js/abstracts/classes/alpha-filters'
// CSS
import '../sass/main.scss'

// === DYNAMIC SCRIPTS IMPORTER
// note: this file MUST be placed before WP Alpha main, otherwise scripts might load too late
import './components/dynamic-scripts-importer'

// === WP ALPHA ASSETS
import '@nematis/wp-alpha/assets/js/main'

// === THIRD PARTY
import './third-party/rousquille'

AlphaFilters.addFilter('module.headerStates.useHoverClass', () => false, 'child/header')
